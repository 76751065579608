<template>
  <div v-if="assignedQuestion === 'yes'">
    <v-form>
      <h2 class="mb-3">Critical Thinking Question</h2>

      <p class="mb-5">
        This is a "Critical Thinking" question. You will be scored more on your thought process than
        your answer, so the key is to write, write, write. Explain the questions you have and how
        you would answer them. Who would you look to for answers (sources)? Who would you believe?
        Make the connections you can make, but most of all explain your thought process.
      </p>

      <p class="mb-5">
        Two or three paragraphs should be sufficient; one or two sentences will surely not be
        sufficient. This question could take up to a half-hour to answer completely.
      </p>

      <p class="mb-5">
        You have learned about calorimetry as a way to measure heat flow. The specific heat capacity
        of a material, <stemble-latex content="$\text{c,}$" /> is the amount of heat needed to raise
        the temperature of 1 kg of the material by 1 K. This is expressed mathematically as:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex
          content="$\displaystyle{\text{c}=\frac{\text{q}}{\text{m}\,\Delta\text{T}}}$"
        />
      </p>

      <p class="mb-5">
        The heat capacity of water is <stemble-latex content="$4184\,\text{J/kg K}$" /> while that
        of liquid mercury is <stemble-latex content="$140\,\text{J/kg K}.$" />
      </p>

      <p class="mb-3">
        a) Assuming that you have 1.00 kg of liquid water and 1.00 kg of liquid mercury and provide
        1000.0 J of heat to each, how much would the temperature of each material change by?
      </p>

      <calculation-input
        v-model="inputs.deltaTWater"
        class="mb-3"
        prepend-text="$\Delta\text{T}_\ce{H2O}:$"
        append-text="$\text{K}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.deltaTHg"
        class="mb-5"
        prepend-text="$\Delta\text{T}_\ce{Hg}:$"
        append-text="$\text{K}$"
        :disabled="!allowEditing"
      />

      <p class="mb-5">
        b) Using the information provided in the figure below, what mass of gold (in kg) would
        observe the same change in temperature as the sample of water upon the absorption of the
        same 1000.0 J of heat?
      </p>

      <p class="mb-4">
        <v-img :src="imageName" />
      </p>

      <calculation-input
        v-model="inputs.massAu"
        prepend-text="$\text{Mass Au}:$"
        append-text="$\text{kg}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>

  <div v-else>
    <h3 style="color: #808080; text-align: center" class="mt-13">
      Sorry, but this is not one of your 3 assigned questions.
    </h3>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'OleMiss116_PL11_Q2',
  components: {CalculationInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        deltaTWater: null,
        deltaTHg: null,
        massAu: null,
      },
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/heatCapacitiesOfVariousMaterials.png';
    },
    lastName() {
      return this.$auth.user.lastName.toUpperCase();
    },
    assignedQuestion() {
      if (
        // !this.lastName.startsWith('A') &&
        // !this.lastName.startsWith('B') &&
        // !this.lastName.startsWith('C') &&
        // !this.lastName.startsWith('D') &&
        // !this.lastName.startsWith('E') &&
        // !this.lastName.startsWith('F') &&
        // !this.lastName.startsWith('G') &&
        // !this.lastName.startsWith('H') &&
        // !this.lastName.startsWith('I') &&
        !this.lastName.startsWith('J') &&
        !this.lastName.startsWith('K') &&
        !this.lastName.startsWith('L') &&
        !this.lastName.startsWith('M') &&
        !this.lastName.startsWith('N') &&
        !this.lastName.startsWith('O') &&
        !this.lastName.startsWith('P') &&
        !this.lastName.startsWith('Q') &&
        !this.lastName.startsWith('R') &&
        !this.lastName.startsWith('S') &&
        !this.lastName.startsWith('T') &&
        !this.lastName.startsWith('U') &&
        !this.lastName.startsWith('V') &&
        !this.lastName.startsWith('W') &&
        !this.lastName.startsWith('X') &&
        !this.lastName.startsWith('Y') &&
        !this.lastName.startsWith('Z')
      ) {
        return 'yes';
      } else {
        return 'no';
      }
    },
  },
};
</script>
